import React, {useState, useEffect} from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    maxLength,
    SelectInput,
    Button,
    useNotify,
    useDataProvider,
} from "react-admin";

const validateRequired = required();

const CreateCar = (props) => {
    const [step, setStep] = useState(1);
    const [vehicleNumber, setVehicleNumber] = useState("");
    const [carInfo, setCarInfo] = useState(null);
    const [beacons, setBeacons] = useState([]);
    const notify = useNotify();
    const dataProvider = useDataProvider();

    useEffect(() => {
        const fetchBeacons = async () => {
            try {
                const {data} = await dataProvider.getList('carrier-car-beacons', {
                    pagination: {page: 1, perPage: 100},
                    sort: {field: 'id', order: 'ASC'},
                    filter: {},
                });
                setBeacons(data);
            } catch (error) {
                notify('Error fetching beacons', 'error');
            }
        };

        fetchBeacons();
    }, [dataProvider, notify]);

    const handleVehicleNumberSubmit = async () => {
        try {
            // Make a GET request to fetch car info based on vehicleNumber
            const {data} = await dataProvider.getOne('cars/plate', {id: vehicleNumber});
            if (!data) {
                notify('For next step you need to get car info, contact association.', 'error');
                return;
            }
            setCarInfo(data);
            setStep(2);
        } catch (error) {
            notify('Error fetching car info, server problem', 'error');
        }
    };

    if (step === 1) {
        return (
            <Create {...props} title="resources.cars.create">
                <SimpleForm toolbar={null}>
                    <TextInput
                        source="vehicleNumber"
                        validate={[validateRequired, maxLength(12)]}
                        value={vehicleNumber}
                        onChange={(e) => setVehicleNumber(e.target.value)}
                    />
                    <Button label="ra.navigation.next" onClick={handleVehicleNumberSubmit}/>
                </SimpleForm>
            </Create>
        );
    }

    return (
        <Create {...props} title="resources.cars.create">
            <SimpleForm redirect="list">
                <SelectInput
                    source="beaconId"
                    choices={beacons}
                    optionText="macAddress"
                    validate={validateRequired}
                />
                <TextInput
                    source="tailNumber"
                    validate={validateRequired}
                    initialValue={carInfo?.tailNumber}
                    disabled
                />
                <TextInput
                    source="vehicleNumber"
                    validate={[validateRequired, maxLength(20)]}
                    initialValue={vehicleNumber}
                    disabled
                />
                <TextInput
                    source="model"
                    validate={validateRequired}
                    initialValue={carInfo?.model}
                />
                <NumberInput
                    source="capacity"
                    step={1}
                    min={0}
                    validate={[validateRequired]}
                    initialValue={carInfo?.capacity}
                />
            </SimpleForm>
        </Create>
    );
};

export default CreateCar;
