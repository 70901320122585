import React from "react";
import {
  Edit,
  maxValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  usePermissions,
} from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import MyStandardEditToolbarWithList from "../../../../SharedComponents/MyStandardEditToolbarWithList.jsx";

const validateRequired = required();

const KindSelect = (props) => {
  return <SelectInput {...props} optionText="name" />;
};

/*eslint-disable react/prop-types*/
const EditBenefit = (props) => {
  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;

  if (!permissions) {
    return null;
  }

  return (
    <Edit {...props} undoable={false} title={<MySimpleBreadcrumbs resource={props.resource} />}>
      <SimpleForm toolbar={<MyStandardEditToolbarWithList nameField="routeName" />} redirect="list">
        <ReferenceInput
          source="kindId"
          reference="benefit-kinds"
          filter={{ langCode: "uk" }}
          validate={validateRequired}
        >
          <KindSelect />
        </ReferenceInput>
        <NumberInput
          source="discount"
          step={0.1}
          min={0}
          max={100}
          validate={[validateRequired, maxValue(100, "Не має перевищувати 100 відсотків")]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default EditBenefit;
