import { useForm, useFormState } from 'react-final-form'
import { Select } from 'mui-rff'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import * as PropTypes from 'prop-types'

const LocationSelect = ({ source, choices, label, setValue }) => {
  const formState = useFormState();
  const form = useForm();

  const selectHandler = ({ target: { value } }) => {
    setValue && setValue(value);
    form.change(source, value);
    if (source === "regionId") {
      form.change("districtId", "");
      form.change("cityId", "");
    }
    if (source === "districtId") {
      form.change("cityId", "");
    }
  };

  return (
    <Select
      name={source}
      value={formState.values[source] || ""}
      onChange={selectHandler}
      label={label}
      variant="outlined"
      required
    >
      <MenuItem value="">&nbsp;</MenuItem>
      {choices.map((choice) => (
        <MenuItem key={choice.id} value={choice.id}>
          {choice.name}
        </MenuItem>
      ))}
    </Select>
  );
};

LocationSelect.propTypes = {
  choices: PropTypes.array,
  source: PropTypes.string,
  label: PropTypes.string,
  setValue: PropTypes.func,
};

LocationSelect.defaultProps = {
  choices: [],
};

export default LocationSelect;
