import React, {useCallback} from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Field} from "react-final-form";
import { makeValidate, TextField } from "mui-rff";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import {useHistory, useParams} from "react-router-dom";
import icInfo from "../../../Assets/ic_info.svg";
import { ROLE_CARRIER } from "../../../Utils/Constants.js";
import ReactFinalForm from "../../../SharedComponents/ReactFinalForm.jsx";
import {useSnackbar} from "../../../Providers/Common/snackbarProvider.jsx";
import {post} from "../../../Utils/NetworkUtils.js";
import {API_URL} from "../../../Constants.js";

const schema = Yup.object().shape({
  phone: Yup.string().matches(/^\+[1-9]\d{1,14}$/, "Phone number must be in international format, e.g. +380XXXXXXXXX"),
  email: Yup.string().email("Invalid email"),
  taxNumber: Yup.string().matches(/^\d{8,}$/, "Invalid tax number"),
});

const validate = makeValidate(schema);

const useStyles = makeStyles((theme) => ({
  carrierRoot: {
    backgroundColor: "#F5F5F5",
  },
  header: {
    fontWeight: 700,
    fontStyle: "italic",
    margin: "4vh 0 4vh",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  inputFields: {
    backgroundColor: "#ECECEC",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "30px",
    padding: "40px 60px 20px",
    [theme.breakpoints.down("md")]: {
      padding: "40px 35px",
    },
    "& .MuiFormControl-root": {
      marginBottom: 20,
      width: "30vw",
      minWidth: 300,
      maxWidth: 500,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
    "& .email-field": {
      marginBottom: 5,
    },
  },
  infoIcon: {
    cursor: "pointer",
    position: "absolute",
    top: 10,
    right: "-35px",
  },
  tooltip: {
    fontSize: 15,
  },
  iconBox: {
    position: "relative",
  }
}));

const CarrierRegistrationExtended = () => {
  const classes = useStyles();
  const { token } = useParams();
  const history = useHistory();
  const snackbar = useSnackbar();

  const onDataSubmit = useCallback(async (data) => {
    try {
      const response = await post(`${API_URL}/carrier-detail/${token}`, data);
      if (response.data) {
        history.push("/registration/extended-success");
      } else {
        snackbar.error(`${response.error.code}: ${response.error.text}`);
      }
    } catch (e) {
      snackbar.error(e.toString());
    }
  }, [history, snackbar, token]);

  return (
      <Box
          className={classes.carrierRoot}
          display="flex"
          flexDirection="column"
          alignItems="center"
      >
        <Typography className={classes.header} variant="h4">
          Carrier Registration Details
        </Typography>
        <ReactFinalForm
            onSubmit={onDataSubmit}
            validate={validate}
            formClassName={classes.form}
        >
          <Field name="role" component="input" type="hidden" defaultValue={ROLE_CARRIER} />
          <Field name="status" component="input" type="hidden" defaultValue={false} />
          <Box className={classes.inputFields} >
            <TextField name="fullName" label="Full Name" variant="outlined" required />
            <TextField name="position" label="Position" variant="outlined" />
            <TextField name="legalAddress" label="Legal Address" variant="outlined" required />
            <TextField name="actualAddress" label="Actual Address" variant="outlined"  />
            <Box className={classes.iconBox}>
              <TextField name="taxNumber" label="Tax Number" variant="outlined" />
              <Tooltip title="Tax Number is a unique identifier issued by the SARS to a taxpayer" arrow placement="bottom-start" classes={{ tooltip: classes.tooltip }}>
                <img className={classes.infoIcon} src={icInfo} alt="Information" />
              </Tooltip>
            </Box>
            <TextField name="bank" label="Bank" variant="outlined" required />
            <TextField name="bankCode" label="Bank Code" variant="outlined"  />
            <TextField name="swift" label="Swift" variant="outlined" helperText="SWIFT codes are crucial for transactions, ensuring that funds are routed to the correct recipient." required />
            <TextField name="phone" label="Phone" variant="outlined" required />
            <TextField className="email-field" name="email" label="E-mail" variant="outlined" required />
            <TextField name="baseDocument" helperText="Carrier's founding document (Charter, other)" label="Founding Document" variant="outlined"  />
            <TextField name="positionName" helperText="Title of the highest official of the Carrier (as in the Charter)" label="Position Title" variant="outlined" />
          </Box>
          <Box display="flex" justifyContent="center" margin="30px 0">
            <Button size="large" type="submit" variant="contained" color="primary">
              Get Contract
            </Button>
          </Box>
        </ReactFinalForm>
      </Box>
  );
};

export default CarrierRegistrationExtended;
