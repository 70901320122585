import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  usePermissions,
  NumberInput,
  SelectInput,
  FormDataConsumer,
} from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import MyStandardEditToolbarWithList from "../../../../SharedComponents/MyStandardEditToolbarWithList.jsx";
import { FARE_CHOICES } from "../../../../Utils/Constants.js";

const validateRequired = required();

/*eslint-disable react/prop-types*/
const EditFare = (props) => {
  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;

  if (!permissions) {
    return null;
  }

  return (
    <Edit {...props} undoable={false} title={<MySimpleBreadcrumbs resource={props.resource} />}>
      <SimpleForm toolbar={<MyStandardEditToolbarWithList nameField="routeName" />} redirect="list">
        <TextInput source="name" validate={validateRequired} />
        <SelectInput source="kind" choices={FARE_CHOICES} validate={validateRequired} />
        <NumberInput source="price" step={0.1} min={0} validate={validateRequired} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.kind !== 'fixed' && (
              <NumberInput {...rest} source="minAmount" step={0.1} min={0} validate={validateRequired} />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default EditFare;
