import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Datagrid, List, TextField, useTranslate, FunctionField, usePermissions } from "react-admin";
import Typography from "@material-ui/core/Typography";
import EditButtons from "../../../../SharedComponents/EditButtons.jsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import useHasCreate from "../../../../hooks/useHasCreate.js";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";

const Session = (props) => {
  const t = useTranslate();
  const { record } = props;
  return !record?.closed ? (
      <Typography>{t("resources.connections.active")}</Typography>
  ) : (
      <Typography>{t('resources.connections.closed')}</Typography>
  );
};

const ListConnections = (props) => {
  const t = useTranslate();
  const hasCreate = useHasCreate();
  const [canCreate, setCanCreate] = useState(true)

  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;

  useEffect(() => {
    setCanCreate(!(permissions?.user?.roles ?? []).includes('ROLE_OPERATOR'));
  }, [permissions])

  return (
      <List
          {...props}
          exporter={false}
          bulkActionButtons={false}
          perPage={25}
          pagination={<MyPagination />}
          filters={<ListTextFilter />}
          hasCreate={props.hasCreate && canCreate}
      >
        <Datagrid>
          <TextField source="id" />
          <TextField source="routeName" />
          <TextField source="routeNumber" />
          <TextField source="vehicleNumber" />
          <TextField source="tailNumber" />
          <FunctionField
              label="Driver Name"
              render={record => record.driverId ? record.driverFullName : t("resources.driver-profile.empty")}
          />
          <FunctionField
              label="resources.connections.fields.session"
              render={record => record.driverId ? <Session sessionActive={record.driverSessionActive} /> : t("resources.connections.empty")}
          />
          <EditButtons
              deleteConfirmTitle={
                /** @param {Connection} record */
                    (record) =>
                    `${t("resources.connections.delete", { carNumber: record.vehicleNumber, routeName: record.routeName })}`
              }
              hasView={!hasCreate || !canCreate}
              hasEdit={hasCreate && canCreate}
              hasDelete={hasCreate && canCreate}
          />
        </Datagrid>
      </List>
  );
};

ListConnections.propTypes = {
  hasCreate: PropTypes.any,
};

export default ListConnections;
