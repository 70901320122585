/* eslint-disable unused-imports/no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import {ListButton, SaveButton, Toolbar, useTranslate} from "react-admin";
import { makeStyles } from "@material-ui/core";
import {ArrowBackIosRounded} from "@material-ui/icons";

const useStyles = makeStyles({
  myToolbar: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
});

/* eslint-disable react/prop-types */
const MyStandardEditToolbarWithList = ({ nameField, customDeleteConfirmText, ...rest }) => {
  const classes = useStyles();
  const t = useTranslate();

  const deleteConfirmTitle = customDeleteConfirmText
    ? customDeleteConfirmText(rest.record)
    : `${t("ra.action.delete")} ${rest.record[nameField]}?`;

  return (
    <Toolbar {...rest} className={classes.myToolbar}>
      <SaveButton disabled={rest.pristine} />
      <ListButton icon={<ArrowBackIosRounded/>} label="Отмена"/>
    </Toolbar>
  );
};

MyStandardEditToolbarWithList.propTypes = {
  nameField: PropTypes.string,
  customDeleteConfirmText: PropTypes.func,
};

export default MyStandardEditToolbarWithList;
