import React, {useState, useEffect} from "react";
import {Create, required, SimpleForm, ReferenceInput, SelectInput, useTranslate} from "react-admin";
import { Box, makeStyles, MuiThemeProvider, TextField } from "@material-ui/core";
import { overrideTheme } from "../../../../Utils/theming.js";
import adminTheme from "../../adminTheme.js";

const validateRequired = required();

const infoTheme = overrideTheme(adminTheme, {
  MuiTextField: {
    root: {
      margin: "10px",
    },
  },
  MuiInputBase: {
    root: {
      minWidth: "200px",
    },
  },
  MuiFormLabel: {
    root: {
      whiteSpace: "nowrap",
    },
  },
});

export const useCreateStyles = makeStyles({
  root: {
    maxWidth: "unset",
  },
});

const useInfoStyles = makeStyles({
  root: {
    border: "1px solid #C4C4C4",
    borderRadius: "5px",
    margin: "0 0 30px",
    display: "flex",
    flexWrap: "wrap",
    padding: 10,
  },
  routeNote: {
    flexGrow: 1,
  },
});

/*eslint-disable react/prop-types*/
export const CarSelectWithInfo = (props) => {
  const classes = useInfoStyles();
  const t = useTranslate();
  /** @type Vehicle[] */
  const { onCarSelect } = props;
  const choices = props.choices;
  const record = choices && choices.find((car) => car.id === props.input.value);
  const recordId = record && record.id;

  useEffect(() => {
    const sendCarId = async (id) => {
      const user = JSON.parse(localStorage.getItem("user"));
      try {
        const response = await fetch(`/api/routes/car/${id}`, {
          headers: { Authorization: `Bearer ${user.token}` }
        });

        if (!response.ok) {
          return Promise.reject(response);
        }

        const data = await response.json();
        onCarSelect(data);

      } catch (e) {
        return Promise.reject(e);
      }
    };

    if (record) {
      sendCarId(recordId);
    }
  }, [recordId]);

  return (
    <Box>
      <SelectInput {...props} choices={choices.filter((c) => !c.hasConnection || c.hasConnection || c.id === props.input.value)} optionText="vehicleNumber" />
      {record && (
        <MuiThemeProvider theme={infoTheme}>
          <Box className={classes.root}>
            <TextField value={record.vehicleNumber} disabled label={t("resources.cars.fields.vehicleNumber")} />
            <TextField value={record.beaconUid} disabled label={t("resources.cars.fields.beaconUid")} />
            <TextField value={record.tailNumber} disabled label={t("resources.cars.fields.tailNumber")} />
          </Box>
        </MuiThemeProvider>
      )}
    </Box>
  );
};

export const RouteSelectWithInfo = (props) => {
  const classes = useInfoStyles();
  const t = useTranslate();
  /** @type Route[] */
  const { routeInfo } = props;

  return (
    <Box>
      {routeInfo && (
        <MuiThemeProvider key={routeInfo.id} theme={infoTheme}>
          <Box className={classes.root}>
            <TextField value={routeInfo.routeName} disabled label={t("resources.routes.fields.routeName")} />
            <TextField value={routeInfo.routeNumber} disabled label={t("resources.routes.fields.routeNumber")} />
            <TextField
              style={{ minWidth: 220 }}
              value={routeInfo.duration}
              disabled
              label={t("resources.routes.fields.duration")}
            />
            <TextField value={routeInfo.distance} disabled label={t("resources.routes.fields.distance")} />
            <TextField value={routeInfo.price} disabled label={t("resources.routes.fields.price")} />
            <TextField value={routeInfo.startPoint} disabled label={t("resources.routes.fields.startPoint")} />
            <TextField value={routeInfo.endPoint} disabled label={t("resources.routes.fields.endPoint")} />
            {/* break flexbox */}
            <div style={{ flexBasis: "100%", height: 0 }} />
            <TextField
              className={classes.routeNote}
              value={routeInfo.note}
              disabled
              label={t("resources.routes.fields.note")}
            />
          </Box>
        </MuiThemeProvider>
      )}
    </Box>
  );
};

/*eslint-disable react/prop-types*/
export const DriverSelectWithInfo = (props) => {
  const classes = useInfoStyles();
  const t = useTranslate();
  /** @type Driver[] */
  const choices = props.choices;
  const record = choices && choices.find((driver) => driver.id === props.input.value);

  return (
    <Box>
      <SelectInput {...props} optionText="fullName" />
      {record && (
        <MuiThemeProvider theme={infoTheme}>
          <Box className={classes.root}>
            <TextField value={record.fullName} disabled label={t("resources.drivers.fields.fullName")} />
            <TextField value={record.personalNumber} disabled label={t("resources.drivers.fields.personalNumber")} />
          </Box>
        </MuiThemeProvider>
      )}
    </Box>
  );
};

const CreateConnection = (props) => {
  const [routeInfo, setRouteInfo] = useState(null);
  const classes = useCreateStyles();
  const updateRouteInfo = data => setRouteInfo(data);

  return (
    <Create {...props} className={classes.root} title="resources.connections.create">
      <SimpleForm redirect="list">
        <ReferenceInput source="carId" reference="cars" validate={validateRequired}>
          <CarSelectWithInfo onCarSelect={updateRouteInfo}/>
        </ReferenceInput>
        <ReferenceInput source="driverId" reference="drivers">
          <DriverSelectWithInfo />
        </ReferenceInput>
        <ReferenceInput source="routeId" reference="routes">
          <RouteSelectWithInfo routeInfo={routeInfo}/>
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateConnection;
