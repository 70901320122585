import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  NumberInput,
  ReferenceInput,
  SelectInput,
  regex,
  useTranslate,
} from "react-admin";
import MyStandardEditToolbar from "../../../../SharedComponents/MyStandardEditToolbar.jsx";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import MaskedTextField from "../../../../SharedComponents/MaskedTextField.jsx";
import { UUID_MASK } from "../../../../Utils/Constants.js";

const validateRequired = required();
const validateMac = regex(/^[a-zA-Z0-9]{12}$/, "Must contain only letters and numbers");
const validateUUID = regex(
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
  "Not valid UUID",
);
/*eslint-disable react/prop-types*/
const SpecialSelect = (props) => {
  return <SelectInput {...props} optionText="companyName" />;
};

/*eslint-disable react/prop-types*/
const EditBeacon = (props) => {
  const t = useTranslate();
  return (
    <Edit {...props} undoable={false} title={<MySimpleBreadcrumbs resource={props.resource} />}>
      <SimpleForm redirect="list" toolbar={<MyStandardEditToolbar nameField="uid" />}>
        <MaskedTextField
          label={t("resources.admin-beacons.fields.uid")}
          mask={UUID_MASK}
          source="uid"
          validate={[validateRequired, validateUUID]}
        />
        <NumberInput source="major" step={1} min={0} validate={validateRequired} />
        <NumberInput source="minor" step={1} min={0} validate={validateRequired} />
        <TextInput inputProps={{ maxLength: 12 }} source="macAddress" validate={[validateRequired, validateMac]} />
        <ReferenceInput source="carrierId" reference="carriers">
          <SpecialSelect />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default EditBeacon;
