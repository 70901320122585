import React, { useEffect, useState } from "react";
import {IconButton, makeStyles} from "@material-ui/core";
import {AppBar, usePermissions, useTranslate} from "react-admin";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import useEffectAsync from "../../../hooks/useEffectAsync.js";
import MyUserMenu from "./MyUserMenu.jsx";
import {ROLE_MUNICIPALITY, ROLE_SUPER_ADMIN} from "../../../Utils/Constants.js";
import LocaleSelector from "./LocaleSelector.jsx";

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.up('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: "20px",
    },
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  userName: {
    margin: "0 20px 0 0",
  },
  icon: {
    color: 'white',
  },
}));

const MyAppbar = (props) => {
  const classes = useStyles();
  const perms = usePermissions();
  const t = useTranslate();
  const [userTitle, setUserTitle] = useState("...");
  const [carrierId, setCarrierId] = useState(null); // Состояние для ID
  /** @type Permissions */
  const permissions = perms.permissions;

  useEffectAsync(async () => {
    if (!permissions) {
      return;
    }

    let companyName = "";
    if (permissions.employee && permissions.employee.companyName) {
      companyName = permissions.employee.companyName + ": ";
    } else if (permissions.carrier && permissions.carrier.companyName) {
      companyName = permissions.carrier.companyName + ": ";
    }
    const roleName = t(`roles.${permissions.user.roles[0]}`);
    const setTitle = (value) => {
      setUserTitle(companyName + roleName + (value ? `: ${value}` : ""));
    };

    if (permissions.user.roles.indexOf(ROLE_SUPER_ADMIN) > -1) {
      setTitle();
    } else if (permissions.carrier) {
      setTitle(`${permissions.carrier.fullName} (${permissions.user.email})`);
    } else if (permissions.employee) {
      setTitle(`${permissions.employee.fullName} (${permissions.user.email})`);
    } else {
      setTitle(permissions.user.email);
    }

    if (permissions.carrier && permissions.user.roles.indexOf(ROLE_MUNICIPALITY) > -1) {
      setCarrierId(permissions.carrier.id); // Устанавливаем ID
    }
  }, [permissions]);

  useEffect(() => {
    const titleHTMLElement = document.getElementById("react-admin-title");
    if (!titleHTMLElement || !titleHTMLElement.firstElementChild) {
      return;
    }
    const text = titleHTMLElement.firstElementChild.innerHTML;
    if (text) {
      document.title = `${t("common.appName")} - ${text}`;
    }
  });

  return (
      <AppBar {...props} userMenu={<MyUserMenu />}>
        <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
        <Typography className={classes.userName}>{userTitle}</Typography>
        <LocaleSelector />
        {carrierId && (
            <Link
                to={`/carrier-detail/carrier/${carrierId}`}
                color="inherit"
            >
              <IconButton className={classes.button}>
                <SettingsOutlinedIcon className={classes.icon} />
              </IconButton>
            </Link>
        )}
      </AppBar>
  );
};

export default MyAppbar;
