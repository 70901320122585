import React from "react";
import {
  Edit,
  TextInput,
  required,
  usePermissions,
  NumberInput,
  ReferenceInput,
  SelectInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  FormTab,
  TabbedForm,
  ArrayInput,
  SimpleFormIterator,
  DeleteButton
} from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import MyStandardEditToolbarWithList from "../../../../SharedComponents/MyStandardEditToolbarWithList.jsx";

const validateRequired = required();

const FareSelect = (props) => {
  return <SelectInput {...props} optionText={(record) => {
    return `${record?.name} (${record?.price} ZAR.)`
  }} />;
};

const BenefitsSelect = (props) => {
  return <AutocompleteArrayInput {...props} optionText={(record) => {
    return `${record?.name} (${record?.discount} %)`
  }} />;
};

/*eslint-disable react/prop-types*/
const EditRoute = (props) => {
  const perms = usePermissions();

  /** @type Permissions */
  const permissions = perms.permissions;
  if (!permissions) {
    return null;

  }

  return (
      <Edit {...props} undoable={false} title={<MySimpleBreadcrumbs resource={props.resource} />}
            sx={{ maxWidth: { lg: '600' } }}
      >
        <TabbedForm
            toolbar={<MyStandardEditToolbarWithList nameField="routeName" />}
            redirect="list"
            initialValues={{
              adminId: permissions.user.id,
            }}
        >
          <FormTab label="General">
            <TextInput source="routeName" validate={validateRequired} />
            <TextInput source="startPoint" validate={validateRequired} />
            <TextInput source="endPoint" validate={validateRequired} />
            <TextInput source="routeNumber" validate={validateRequired} />
            <NumberInput source="duration" step={1} min={1} validate={validateRequired} />
            <NumberInput source="distance" step={0.1} min={1} validate={validateRequired} />
            <ReferenceInput source="fareId" reference="fares" validate={validateRequired}>
              <FareSelect />
            </ReferenceInput>
            <ReferenceArrayInput source="benefitIds" reference="benefits" filter={{ langCode: "uk" }}>
              <BenefitsSelect />
            </ReferenceArrayInput>
            <TextInput source="note" />
          </FormTab>
          <FormTab label="Buses attached to route">
            <ArrayInput source="approvedBusesToOperate" label={"resources.cars.create"} fullWidth={false} sx={{width: '100px'}}>
              <SimpleFormIterator inline>
                <TextInput label={"resources.cars.create"} placeholder="Input vehicle plate number" />
                <DeleteButton label="Delete" />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </TabbedForm>
      </Edit>
  );
};

export default EditRoute;
