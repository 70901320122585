import React from 'react';
import {Button, useTranslate} from 'react-admin';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {useHistory} from 'react-router-dom';

const ViewButton = ({record}) => {
    const t = useTranslate();
    const history = useHistory();

    const redirectToDetail = () => {
        // Navigate to the detail page with the record's ID
        history.push(`/view/${record.id}`);
    };

    return (
        <Button onClick={redirectToDetail} label={t("ra.action.show")}>
            <VisibilityIcon/>
        </Button>
    );
};

export default ViewButton;
