import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  Filter,
  useTranslate,
  useLocale,
  TopToolbar,
  ExportButton
} from 'react-admin'
import {  MuiThemeProvider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import XLSX from "xlsx";
import Box from '@material-ui/core/Box'
import MaterialDateTimeInput from "../../../../SharedComponents/MaterialDateTimeInput.jsx";
import { dateToString } from "../../../../Utils/DateUtils.js";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import { overrideTheme } from "../../../../Utils/theming.js";
import adminTheme from "../../adminTheme.js";
import TicketCounterWidget from './TicketCounterWidget.jsx'

const filterTheme = overrideTheme(adminTheme, {
  MuiInputBase: {
    root: {
      minWidth: 200,
    },
  },
  MuiFilledInput: {
    adornedEnd: {
      paddingRight: 0,
    },
  },
});

/*eslint-disable react/prop-types*/
const DateMonthTextField = (props) => {
  const { record, dateType } = props;
  return <Typography>{dateToString(new Date(record[dateType]), "dd.MM.yyyy")}</Typography>;
};

const Filters = (props) => {
  return (
    <MuiThemeProvider theme={filterTheme}>
      <Filter {...props} >
        {/* eslint-disable-next-line react/prop-types */}
        <MaterialDateTimeInput source="startedAt" alwaysOn resource={props.resource} />
        {/* eslint-disable-next-line react/prop-types */}
        <MaterialDateTimeInput source="finishedAt" alwaysOn resource={props.resource} />
      </Filter>
    </MuiThemeProvider>
  );
};

const exporter = (t, locale) => {
  /** @param {Trips[]} data */
  const f = function (data) {
    const dataForExport = data.map((item) => {
      item.createdAt = dateToString(new Date(item.startedAt), "dd.MM.yyyy");
      //eslint-disable-next-line unused-imports/no-unused-vars
      const {  finishedAt, ...itemForExport } = item;
      return itemForExport;
    });
    const headers = Object.keys(dataForExport[0]).map((key) => t(`resources.trips.fields.${key}`));
    const ws = XLSX.utils.aoa_to_sheet([headers]);
    XLSX.utils.sheet_add_json(ws, dataForExport, { origin: "A2", skipHeader: true });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "tickets");
    XLSX.writeFile(wb, "tickets.xlsx");
  };
  return f;
};

const listTheme = overrideTheme(adminTheme, {
  RaList: {
    main: {
      marginTop: 20,
      flexDirection: "column",
    },
  },
});

export const ListTrips = (props) => {
  const t = useTranslate();
  const locale = useLocale();

  const ListActions = () => (
    <TopToolbar>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <TicketCounterWidget/>
        <Box display="flex">
          <ExportButton label={t('ra.action.exportAll')} maxResults={1000000}/>
          <ExportButton/>
        </Box>
      </Box>
    </TopToolbar>
  );

  return (
    <MuiThemeProvider theme={listTheme}>
      <List
        {...props}
        hasCreate={false}
        exporter={exporter(t, locale)}
        actions={<ListActions />}
        bulkActionButtons={false}
        filters={<Filters />}
        perPage={10}
        pagination={<MyPagination />}
      >
        <Datagrid>
          <TextField source="id" label={t('resources.trips.fields.index')}/>
          <DateMonthTextField source="startedAt" dateType="startedAt" label={t('resources.trips.fields.startedAt')}/>
          <DateMonthTextField source="finishedAt" dateType="finishedAt"/>
          <TextField source="passengerName" label={t('resources.carrier-requests.fields.phone')} sortable={false} />
          <TextField source="driverFullName" sortable={false} />
          <TextField source="routeName" sortable={false} />
        </Datagrid>
      </List>
    </MuiThemeProvider>
  );
};
