import React from "react";
import {Edit, SimpleForm, TextInput, required, maxLength, NumberInput, ReferenceInput, SelectInput} from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import MyStandardEditToolbarWithList from "../../../../SharedComponents/MyStandardEditToolbarWithList.jsx";
const validateRequired = required();

/*eslint-disable react/prop-types*/
const SpecialSelect = (props) => {
  return (
    <SelectInput
      {...props}
      optionText="macAddress"
    />
  );
};

/*eslint-disable react/prop-types*/
const EditCar = (props) => {
  const { id }  = props
  return (
    <Edit {...props} undoable={false} title={<MySimpleBreadcrumbs resource={props.resource} />}>
      <SimpleForm redirect="list" toolbar={<MyStandardEditToolbarWithList nameField="vehicleNumber" />}>
        <ReferenceInput filter={{'carId': id}} source="beaconId" reference="carrier-car-beacons" validate={validateRequired}>
          <SpecialSelect />
        </ReferenceInput>
        <TextInput source="tailNumber" validate={validateRequired} disabled/>
        <TextInput source="vehicleNumber" validate={[validateRequired, maxLength(8)]} />
        <TextInput source="model" validate={validateRequired} />
        <NumberInput source="capacity" step={1} min={0} validate={validateRequired} />
      </SimpleForm>
    </Edit>
  );
};

export default EditCar;
