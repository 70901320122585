import React from "react";
import { Datagrid, List, TextField, EmailField } from "react-admin";
import EditButtons from "../../../../SharedComponents/EditButtons.jsx";
import RoleTextField from "../../../../SharedComponents/RoleTextField.jsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";

export const ListEmployees = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      hasCreate={true}
      perPage={25}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
    >
      <Datagrid>
        <TextField source="index" />
        <TextField source="fullName" />
        <RoleTextField sortable={false} source="role" />
        <EmailField source="email" />
        <EditButtons deleteConfirmTitleSource="fullName" />
      </Datagrid>
    </List>
  );
};
