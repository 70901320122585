import React from "react";
import { Datagrid, List, TextField, EmailField } from "react-admin";
import TextCreatedField from "../../../../SharedComponents/TextCreatedField.jsx";
import EditButtons from "../../../../SharedComponents/EditButtons.jsx";
import RoleTextField from "../../../../SharedComponents/RoleTextField.jsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";
import ViewButton from "../../../../SharedComponents/ViewButton.jsx";

export const ListCarriers = (props) => {
    return (
        <List
            {...props}
            exporter={false}
            bulkActionButtons={false}
            hasCreate={false}
            perPage={25}
            pagination={<MyPagination />}
            filters={<ListTextFilter />}
        >
            <Datagrid>
                <TextField source="companyName" />
                <TextField source="regionName" />
                <TextField source="districtName" />
                <TextField source="cityName" />
                <RoleTextField source="role" />
                <EmailField source="email" />
                <TextCreatedField />
                <EditButtons hasDelete={false} deleteConfirmTitleSource="companyName" />
                <ViewButton />
            </Datagrid>
        </List>
    );
};
