import locizer from 'locizer'

import polyglotI18nProvider from "./polyglotI18nProvider.js";
import ru from "./locales/ru/common.json";
import uk from "./locales/uk/common.json";
import en from "./locales/en/common.json";
import sl from "./locales/sl/common.json";
import { LOCIZE_PID } from '../../Constants.js';

const messages = { ru, uk, en, sl };
const DEFAULT_LOCALE = 'en';

locizer.init({
  projectId: LOCIZE_PID,
  loadIfTranslatedOver: 0.1,
  reloadInterval: 3600000
});

const locLoader = (locale) => {
  if (locale === DEFAULT_LOCALE) {
    // initial call, must return synchronously
    console.log(locale);
    console.log(messages);
    return messages[locale];
  }
  return new Promise((resolve, reject) => {
    // resolve(messages[locale]);
    locizer.detector.detect();
    locizer.load("common", locale, (err, messages) => {
      console.log(locale);
      console.log(messages);
      if (err) return reject(err);
      resolve(messages);}
    );
  })

}

const i18nProvider = polyglotI18nProvider(locLoader);

export default i18nProvider;
