import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {SimpleForm, TextInput, required, Toolbar, SaveButton, email, useTranslate} from "react-admin";
import { useParams } from "react-router-dom";
import {useFormState} from "react-final-form";

const validateRequired = required();
const validateEmail = email();

const CustomToolbar = ({ handleSubmitWithRedirect }) => {
    const { invalid } = useFormState();
    return (
        <Toolbar>
            <SaveButton handleSubmitWithRedirect={handleSubmitWithRedirect} disabled={invalid} />
        </Toolbar>
    );
};
const EditCarrierDetails = () => {
    const { id } = useParams();
    const t = useTranslate();
    const [formData, setFormData] = useState({
        id: '',
        fullName: '',
        email: '',
        legalAddress: '',
        actualAddress: '',
        taxNumber: '',
        bank: '',
        bankCode: '',
        swift: '',
        phone: ''
    });

    useEffect(() => {
        const fetchCarrierData = async (id) => {
            const user = JSON.parse(localStorage.getItem('user'));
            try {
                const response = await fetch(`/api/carrier-detail/carrier/${id}`, {
                    headers: { Authorization: `Bearer ${user.token}` }
                });

                if (!response.ok) {
                    return Promise.reject(response);
                }

                const data = await response.json();
                setFormData({
                    id: data.id,
                    fullName: data.fullName,
                    email: data.email,
                    legalAddress: data.legalAddress,
                    actualAddress: data.actualAddress,
                    taxNumber: data.taxNumber,
                    bank: data.bank,
                    bankCode: data.bankCode,
                    swift: data.swift,
                    phone: data.phone
                });
            } catch (e) {
                console.error(e);
            }
        };

        if (id) {
            fetchCarrierData(id);
        }
    }, [id]);

    const handleSubmit = async (values) => {
        const user = JSON.parse(localStorage.getItem('user'));
        try {
            const response = await fetch(`/api/carrier-detail/${values.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}`},
                body: JSON.stringify(values)
            });

            if (!response.ok) {
                return Promise.reject(response);
            }

        } catch (e) {
            console.error(e);
        }
    };

    return (
        <SimpleForm initialValues={formData} save={handleSubmit} toolbar={<CustomToolbar handleSubmitWithRedirect={handleSubmit} />}>
            <TextInput source="fullName" validate={validateRequired} label={t('resources.carrier-requests.fields.fullName')} />
            <TextInput source="phone" validate={validateRequired} label={t('resources.carrier-requests.fields.phone')} />
            <TextInput source="email" validate={[validateRequired, validateEmail]} label={t('resources.carrier-requests.fields.email')} />
            <TextInput source="legalAddress" label={t('resources.carrier-details.fields.legalAddress')} />
            <TextInput source="actualAddress" label={t('resources.carrier-details.fields.actualAddress')} />
            <TextInput source="taxNumber" label={t('resources.carrier-requests.fields.taxNumber')} />
            <TextInput source="bank" label={t('resources.carrier-details.fields.bank')} />
            <TextInput source="bankCode" label={t('resources.carrier-details.fields.bankCode')} />
            <TextInput source="swift" validate={validateRequired} label="SWIFT" />
        </SimpleForm>
    );
};

CustomToolbar.propTypes = {
    handleSubmitWithRedirect: PropTypes.func,
};
export default EditCarrierDetails;
