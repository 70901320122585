import React, { useCallback } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { TextField } from "mui-rff";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import useTitle from "../../../hooks/useTitle.js";
import { useAuthContext } from "../../../Providers/Auth/authProvider.jsx";
import ReactFinalForm from "../../../SharedComponents/ReactFinalForm.jsx";

const useStyles = makeStyles({
  header: {
    fontWeight: 700,
    fontStyle: "italic",
    marginBottom: 40,
    textAlign: "center",
  },
  form: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      marginBottom: 30,
    },
  },
  submitButton: {
    marginBottom: 30,
  },
  smallButton: {
    textTransform: "none",
    marginBottom: 10,
  },
  forgotPasswordButton: {
    fontWeight: 300,
  },
});

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useAuthContext();

  useTitle("Portal “HambaPay” - Sign in");

  const onDataSubmit = useCallback(async (formValues) => {
    await auth.signIn(formValues);
    history.replace("/admin");
  }, [auth, history]);

  return (
    <Box bgcolor="#F5F5F5" minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
      <Box bgcolor="#ECECEC" padding="60px" paddingBottom="10px" display="flex" flexDirection="column">
        <Typography className={classes.header} variant="h5">
          HambaPay
        </Typography>
        <ReactFinalForm onSubmit={onDataSubmit} formClassName={classes.form}>
          <Box display="flex" flexDirection="column">
            <TextField name="email" label="Email" type="email" variant="outlined" fullWidth={false} required />
            <TextField name="password" label="Password" type="password" fullWidth={false} variant="outlined" required />
            <Button className={classes.submitButton} size="large" type="submit" variant="contained" color="primary">
              Sign in
            </Button>
          </Box>
        </ReactFinalForm>
        <Button className={classes.smallButton} size="small" onClick={() => history.push("/registration/role")}>
          Sign up
        </Button>
        <Button
          className={clsx(classes.smallButton, classes.forgotPasswordButton)}
          size="small"
          onClick={() => history.push("/restore-password")}
        >
          Forgot password?
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
