import React, { useEffect } from "react";
import { connect } from "react-redux";
import { userLogin } from "react-admin";
import { Redirect } from "react-router-dom";

//eslint-disable-next-line react/prop-types
const AdminLogin = ({ userLogin }) => {
  useEffect(() => {
    userLogin && userLogin();
  }, [userLogin]);

  return <Redirect to="/" />;
};

export default connect(undefined, { userLogin })(AdminLogin);
