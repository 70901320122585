import React from "react";
import { ReferenceField, Show, SimpleShowLayout, TextField } from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";

/*eslint-disable react/prop-types*/
const ShowConnection = (props) => (
  <Show {...props} title={<MySimpleBreadcrumbs resource={props.resource} />}>
    <SimpleShowLayout>
      <TextField source="beaconUid" />
      <TextField source="vehicleNumber" />
      <TextField source="tailNumber" />
      <TextField source="routeName" />
      <TextField source="routeNumber" />
      <ReferenceField source="driverId" reference="drivers" link={false}>
        <TextField source="fullName" />
      </ReferenceField>
      <ReferenceField 
        label={"resources.connections.fields.price"}
        source="routeId" reference="routes" link={false}>
        <TextField source="price" />
      </ReferenceField>
      <ReferenceField 
        label={"resources.connections.fields.note"}
        source="routeId" reference="routes" link={false}>
        <TextField source="note" />
      </ReferenceField>
      
    </SimpleShowLayout>
  </Show>
);

export default ShowConnection;
