import React, {useState} from "react";
import { Edit, SimpleForm, required, ReferenceInput, useTranslate } from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import { CarSelectWithInfo, DriverSelectWithInfo, RouteSelectWithInfo, useCreateStyles } from "./CreateConnection.jsx";
import MyStandardEditToolbarWithList from "../../../../SharedComponents/MyStandardEditToolbarWithList.jsx";

const validateRequired = required();

/*eslint-disable react/prop-types*/
const EditConnection = (props) => {
  const [routeInfo, setRouteInfo] = useState(null);
  const classes = useCreateStyles();
  const t = useTranslate();
  const updateRouteInfo = data => setRouteInfo(data);

  return (
    <Edit
      {...props}
      className={classes.root}
      undoable={false}
      title={<MySimpleBreadcrumbs resource={props.resource} />}
    >
      <SimpleForm
        redirect="list"
        toolbar={
          <MyStandardEditToolbarWithList
            customDeleteConfirmText={
              /** @param {Connection} record */
              (record) =>
                `${t("resources.connections.delete", { carNumber: record.vehicleNumber, routeName: record.routeName })}`
            }
          />
        }
      >
        <ReferenceInput source="carId" reference="cars" validate={validateRequired}>
          <CarSelectWithInfo onCarSelect={updateRouteInfo} />
        </ReferenceInput>
        <ReferenceInput source="driverId" reference="drivers">
          <DriverSelectWithInfo />
        </ReferenceInput>
        <ReferenceInput source="routeId" reference="routes" validate={validateRequired}>
          <RouteSelectWithInfo routeInfo={routeInfo} />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default EditConnection;
