import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import useTitle from "../../../hooks/useTitle.js";
import mainStyles from "../../../Assets/js/mainStyles.js";

const useStyles = makeStyles((theme) => ({
  congratRoot: {
    backgroundColor: "#F5F5F5",
    height: "calc(100vh - 48px)",
  },
  header: {
    fontWeight: 700,
    fontStyle: "italic",
    margin: "5vh 0 7vh",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  message: {
    maxWidth: 420,
    textAlign: "center",
  },
  buttonBack: {
    marginBottom: 80,
  },
}));

const useStylesMain = makeStyles(mainStyles);

const CongratulationPage = () => {
  const classes = useStyles();
  const classesMain = useStylesMain();
  const history = useHistory();

  useTitle("HambaPay - Registration is completed");

  return (
    <Box
      className={classes.congratRoot}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography className={classes.header} variant="h4">
        Success!
      </Typography>
      <Typography className={classes.message} variant="h6">
        Your application is accepted. Our administrator will review it as soon as possible.
      </Typography>
      <Button
        className={clsx(classesMain.button, classesMain.primaryBtn, classes.buttonBack)}
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          history.push("/login");
        }}
      >
        Return to Home
      </Button>
    </Box>
  );
};

export default CongratulationPage;
