/* eslint-disable react/prop-types */
import React from "react";
import TextField from "@material-ui/core/TextField";
import { useInput } from "react-admin";
import { IMaskInput } from "react-imask";

function TextMaskCustom(props) {
  const { inputRef, mask, onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={mask}
      definitions={{
        "#": /[a-fA-F\d]/,
        "@": /[089abAB]/,
        0: /[\d]/
      }}
      inputRef={inputRef}
      onAccept={(value) => {
        return onChange({ target: { name: props.name, value } });
      }}
      overwrite
    />
  );
}

const MaskedTextField = (props) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const { mask, label } = props;
  return (
    <TextField
      name={name}
      label={label}
      onChange={onChange}
      error={touched && !!error}
      helperText={(touched && error) ? error.message : ' '}
      required={isRequired}
      variant="filled"
      {...rest}
      InputProps={{
        inputComponent: TextMaskCustom,
        inputProps: { mask },
      }}
      
    />
  );
};

export default MaskedTextField;
