import React, { useCallback } from "react";
import * as PropTypes from "prop-types";
import {ReferenceInput, useTranslate} from "react-admin";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useForm, useFormState } from "react-final-form";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
  selectRoot: {
    maxHeight: 48,
  },
  selectLabel: {
    transform: "translate(12px, 16px)",
  },
  referenceInputRoot: {
    marginBottom: 5,
  },
});

const SpecialSelect = ({ resource, source, choices, className, minWidth, otherChoices }) => {
  const classes = useStyles();
  const t = useTranslate();
  const formState = useFormState();
  const form = useForm();
  const choicesData = otherChoices ? otherChoices : choices;

  const selectHandler = useCallback(
    (event) => {
      let value1 = event.target.value;
      form.change(source, value1);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [source],
  );

  return (
    <FormControl variant="filled">
      <InputLabel className={classes.selectLabel} id="demo-simple-select-label">
        {t(`resources.${resource}.fields.${source}`)}
      </InputLabel>
      <Select
        className={clsx(classes.selectRoot, className)}
        style={{ minWidth }}
        value={formState.values[source] || ""}
        onChange={selectHandler}
      >
        <MenuItem value="">&nbsp;</MenuItem>
        {choicesData.map((choice) => (
          <MenuItem key={choice.id} value={otherChoices && source === 'carrier' ? choice.id : choice[source]}>
            {choice[source]}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText />
    </FormControl>
  );
};

SpecialSelect.propTypes = {
  choices: PropTypes.array,
  className: PropTypes.string,
  minWidth: PropTypes.number,
  resource: PropTypes.string,
  source: PropTypes.string,
  otherChoices: PropTypes.array,
};

SpecialSelect.defaultProps = {
  minWidth: 200,
};

const ReferenceFilterByName = ({ className, minWidth, otherChoices, ...rest }) => {
  const classes = useStyles();

  return (
    <ReferenceInput {...rest} className={clsx(classes.referenceInputRoot, className)}>
      <SpecialSelect className={className} minWidth={minWidth} otherChoices={otherChoices}/>
    </ReferenceInput>
  );
};

ReferenceFilterByName.propTypes = {
  alwaysOn: PropTypes.bool,
  className: PropTypes.string,
  minWidth: PropTypes.number,
  otherChoices: PropTypes.array,
};

ReferenceFilterByName.defaultProps = {
  alwaysOn: true,
};

export default ReferenceFilterByName;
