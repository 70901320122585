import React from "react";
import {
  Create,
  maxValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  usePermissions,
} from "react-admin";

const validateRequired = required();
const KindSelect = (props) => {
  console.log(props);
  return <SelectInput {...props} optionText="name" />;
};

const CreateBenefit = (props) => {
  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;

  if (!permissions) {
    return null;
  }

  return (
    <Create {...props} >
      <SimpleForm redirect="list">
        <ReferenceInput
          source="kindId"
          reference="benefit-kinds"
          filter={{ langCode: "uk" }}
          validate={validateRequired}
        >
          <KindSelect />
        </ReferenceInput>
        <NumberInput
          source="discount"
          step={0.1}
          min={0}
          max={100}
          validate={[validateRequired, maxValue(100, "Не має перевищувати 100 відсотків")]}
        />
      </SimpleForm>
    </Create>
  );
};

export default CreateBenefit;
