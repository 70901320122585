/* eslint-disable unused-imports/no-unused-vars */
import React from "react";
import { usePermissions } from "react-admin";
import EditDriver from "./EditDriver.jsx";
import ToolbarWithoutDeleteButton from "../../../../SharedComponents/ToolbarWithoutDeleteButton.jsx";

//eslint-disable-next-line react/prop-types
const MyDriverProfile = ({ staticContext, ...props }) => {
  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;

  return (
    (permissions && permissions.driver && (
      <EditDriver
        {...props}
        id={permissions.driver.id}
        resource="driver-profile"
        basePath="/driver-profile"
        redirect={false}
        toolbar={<ToolbarWithoutDeleteButton />}
      />
    )) ||
    null
  );
};

export default MyDriverProfile;
