import React, { useEffect, useState } from 'react'
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types'
import { useListContext } from 'react-admin'

const useStyles = makeStyles({
    card: {
        width: "fit-content",
        minWidth: 275,
        marginBottom: 20,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export const TicketCounterWidget = () => {
    const classes = useStyles();
    const listContext = useListContext();
    const [total, setTotal] = useState( 0);

    useEffect(() => {
        const totalData = listContext?.total;
        if (totalData) {
            setTotal(totalData);
        }
    }, [listContext.total]);

    if (!total) return null

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Ticket Sales
                </Typography>
                <Typography variant="h5" component="h2">
                    Total Tickets Sold: {total}
                </Typography>
            </CardContent>
        </Card>
    );
};

TicketCounterWidget.propTypes = {
    totalTickets: PropTypes.number.isRequired
}

export default TicketCounterWidget;
