import React, { useState, useEffect } from "react";
import { Datagrid, List, TextField, usePermissions } from "react-admin";
import useHasCreate from "../../../../hooks/useHasCreate.js";
import EditButtons from "../../../../SharedComponents/EditButtons.jsx";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";

export const ListCars = (props) => {
  const hasCreate = useHasCreate();
  const [show, setShow] = useState(false)

  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;

  useEffect(() => {
    setShow((permissions?.user?.roles ?? []).includes('ROLE_OPERATOR'));
  }, [permissions])

  if (show) return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={25}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
    >
      <Datagrid>
        <TextField source="index" />
        <TextField source="beaconUid" />
        <TextField source="beaconMacAddress" />
        <TextField source="vehicleNumber" />
        <TextField source="tailNumber" />
        <TextField source="model" />
        <TextField source="capacity" />
        <EditButtons
          deleteConfirmTitleSource="vehicleNumber"
          hasView={!hasCreate}
          hasEdit={hasCreate}
          hasDelete={hasCreate}
        />
      </Datagrid>
    </List>
  ); 

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={25}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
    >
      <Datagrid>
        <TextField source="index" />
        <TextField source="vehicleNumber" />
        <TextField source="tailNumber" />
        <TextField source="model" />
        <TextField source="capacity" />
        <EditButtons
          deleteConfirmTitleSource="vehicleNumber"
          hasView={!hasCreate}
          hasEdit={hasCreate}
          hasDelete={hasCreate}
        />
      </Datagrid>
    </List>
  );
};