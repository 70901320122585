import merge from "lodash/merge.js";
import theme from "../../appTheme.js";

const adminTheme = merge({}, theme, {
  overrides: {
    MuiInputBase: {
      root: {
        minWidth: 500,
        [theme.breakpoints.down("sm")]: {
          minWidth: 300,
        },
        "&$disabled": {
          color: theme.palette.secondary.light,
        },
      },
    },
    MuiTablePagination: {
      selectRoot: {
        minWidth: "unset",
      },
    },
    MuiFormLabel: {
      root: {
        "&$disabled": {
          color: theme.palette.secondary.light,
          fontWeight: 600,
        },
      },
    },
    RaFormInput: {
      input: {
        width: 500,
        [theme.breakpoints.down("sm")]: {
          width: 300,
        },
      },
    },
    RaMenuItemLink: {
      root: {
        color: "#FFFFFF",
        "&:hover": {
          backgroundColor: theme.palette.secondary.light,
        },
      },
      active: {
        backgroundColor: theme.palette.primary.main,
        fontWeight: "bold",
        color: "#FFFFFF",
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      icon: {
        color: "#FFFFFF",
      },
    },
    RaLayout: {
      content: {
        backgroundColor: "#F5F5F5",
      },
    },
    RaEdit: {
      root: {
        maxWidth: 700,
        margin: "0 0 20px 15px",
        [theme.breakpoints.down("sm")]: {
          maxWidth: 340,
        },
      },
    },
    RaCreate: {
      root: {
        maxWidth: 540,
        margin: "0 0 20px 15px",
        [theme.breakpoints.down("sm")]: {
          maxWidth: 340,
        },
      },
    },
    RaList: {
      root: {
        margin: "0 0 0 15px",
      },
    },
    RaShow: {
      main: {
        marginLeft: 10,
        maxWidth: 500,
      },
    },
    RaListToolbar: {
      actions: {
        paddingTop: 14,
      },
    },
    RaFilterForm: {
      form: {
        marginTop: 5,
        alignItems: "flex-start",
        minHeight: "unset",
      },
    },
    RaSearchInput: {
      input: {
        marginTop: null,
      },
    },
    RaSidebar: {
      fixed: {
        backgroundColor: theme.palette.secondary.main,
        width: "240px"
      }
    }
  },
});

export default adminTheme;
