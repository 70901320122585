import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import useTitle from "../../../hooks/useTitle.js";
import mainStyles from "../../../Assets/js/mainStyles.js";

const useStyles = makeStyles((theme) => ({
  congratRoot: {
    backgroundColor: "#F5F5F5",
    height: "calc(100vh - 48px)",
  },
  header: {
    fontWeight: 700,
    fontStyle: "italic",
    margin: "5vh 0 7vh",
    padding: "0 85px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  message: {
    maxWidth: "60%",
    textAlign: "inherit",
  },
  buttonBack: {
    marginBottom: 80,
  },
}));

const useStylesMain = makeStyles(mainStyles);

const CongratulationExtendedPage = () => {
  const classes = useStyles();
  const classesMain = useStylesMain();
  const history = useHistory();

  useTitle("HambaPay - Registration Completed");

  return (
      <Box
          className={classes.congratRoot}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
      >
        <Typography className={classes.header} variant="h4">
          You are registered in the My Ticket System.
          Please check the email address you provided during registration.
          A Service Agreement has been sent to you for signing.
        </Typography>
        <Typography variant="h5">
          To get started, you need to:
        </Typography>
        <Typography className={classes.message} variant="h6">
          1. Print and sign the received Agreement in two copies. <br/>
          2. Send the following document package by registered mail:
          - Signed Agreement (2 copies),
          Mailing address: 61052, Kharkiv, P.O. Box 10556
        </Typography>
        <Button
            className={clsx(classesMain.button, classesMain.primaryBtn, classes.buttonBack)}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              history.push("/login");
            }}
        >
          Return to Home
        </Button>
      </Box>
  );
};

export default CongratulationExtendedPage;
