import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import EditButtons from "../../../../SharedComponents/EditButtons.jsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";

const ListBenefits = (props) => {  
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={25}
      pagination={<MyPagination />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="index" />
        <TextField source="kind.name" />
        <TextField source="discount" />
        <EditButtons
          deleteConfirmTitleSource="id"
          hasEdit={true}
          hasDelete={true}
        />
      </Datagrid>
    </List>
  );
};

export default ListBenefits;
