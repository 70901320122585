import React, { useCallback, useState } from 'react'
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Field } from "react-final-form";
import { makeValidate, TextField } from "mui-rff";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import useTitle from "../../../hooks/useTitle.js";
import OrangeButton from "../../../SharedComponents/OrangeButton.jsx";
import ReactFinalForm from "../../../SharedComponents/ReactFinalForm.jsx";
import { useRegistrationContext } from "../../../Providers/Registration/registrationProvider.jsx";
import mainStyles from "../../../Assets/js/mainStyles.js";
import LocationSelect from '../../../SharedComponents/LocationSelect.jsx';
import useRegions from '../../../hooks/useRegions.js';
import useEffectAsync from '../../../hooks/useEffectAsync.js';
import { get } from '../../../Utils/NetworkUtils.js';
import { API_URL } from '../../../Constants.js';

const schema = Yup.object().shape({
  email: Yup.string().email("Invalid Email"),
});

const validate = makeValidate(schema);

const useStyles = makeStyles((theme) => ({
  municipalityRoot: {
    backgroundColor: "#F5F5F5",
  },
  header: {
    fontWeight: 700,
    fontStyle: "italic",
    margin: "5vh 0 7vh",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  form: {
    //width: "30%",
    //minWidth: 422,
  },
  emailHint: {
    marginBottom: 20,
  },
}));

const useStylesMain = makeStyles(mainStyles);

const MunicipalityRegistration = () => {
  const classes = useStyles();
  const classesMain = useStylesMain();
  const history = useHistory();
  const { setRegistrationData, registrationData } = useRegistrationContext();
  const regions = useRegions();
  const [regionId, setRegionId] = useState((registrationData && registrationData.regionId) || 0);
  const [districtId, setDistrictId] = useState((registrationData && registrationData.districtId) || 0);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);

  useTitle("HambaPay - Entering registration data");

  useEffectAsync(async () => {
    /** @type {{data: {count: Number, items: Region[]}}} */
    const resp = regionId ? await get(`${API_URL}/districts/region/${regionId}`) : null;
    setDistricts(resp ? resp.data : []);
  }, [regionId]);

  useEffectAsync(async () => {
    /** @type {{data: {count: Number, items: Region[]}}} */
    const resp = regionId && districtId ? await get(`${API_URL}/cities/district/${districtId}`) : null;
    setCities(resp ? resp.data : []);
  }, [districtId, regionId]);

  const onDataSubmit = useCallback(async (formValues) => {
    setRegistrationData(formValues);
    history.push("/registration/final");
  }, [history, setRegistrationData]);

  return (
    <Box
      className={classes.municipalityRoot}
      minHeight="calc(100vh - 48px)"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography className={classes.header} variant="h4">
        Registration data
      </Typography>
      <ReactFinalForm onSubmit={onDataSubmit} validate={validate} formClassName={classes.form}>
        <Field name="role" component="input" type="hidden" defaultValue={"ROLE_MUNICIPALITY"} />
        <Box className={classesMain.inputFields} display="flex" flexDirection="column" borderRadius="30px" padding="60px">
          <LocationSelect source="regionId" label="Region" choices={regions} setValue={(value) => setRegionId(value)} />
          <LocationSelect
            source="districtId"
            label="District"
            choices={districts}
            setValue={(value) => setDistrictId(value)}
          />
          <LocationSelect source="cityId" label="City" choices={cities} />
          <TextField className="email-field" name="email" label="Email" variant="outlined" required />
          <Typography className={classes.emailHint} variant="body2">
            *you will receive a confirmation email to this email
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" margin="30px 0">
          <OrangeButton className={classesMain.button} onClick={() => history.goBack()}>
            Back
          </OrangeButton>
          <Button className={clsx(classesMain.button, classesMain.primaryBtn)} type="submit">
            Next
          </Button>
        </Box>
      </ReactFinalForm>
    </Box>
  );
};

export default MunicipalityRegistration;
