import React from "react";
import { Datagrid, List, TextField, useTranslate } from "react-admin";
import EditButtons from "../../../../SharedComponents/EditButtons.jsx";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";

const KindField = ({ record, ...rest }) => {
  const translate = useTranslate();

  const newRecord = { ...record };

  if (record) {
    newRecord.kind = translate(`fare_kinds.${record.kind}`);
  }

  return <TextField {...rest} record={newRecord} />;
};

const ListFares = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={25}
      hasCreate={true}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="index" />
        <TextField source="name" />
        <TextField source="price" />
        <TextField source="minAmount" />
        <KindField source="kind" />
        <EditButtons deleteConfirmTitleSource="name" hasEdit={true} hasDelete={true} />
      </Datagrid>
    </List>
  );
};

export default ListFares;
