import { Typography } from "@material-ui/core";
import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import { dateToString } from "../../../../Utils/DateUtils.js";

/*eslint-disable react/prop-types*/
const DateMonthTextField = (props) => {
  const { record, dateType } = props;
  return <Typography>{dateToString(new Date(record[dateType]), "dd.MM.yyyy")}</Typography>;
};

export const ListCarrierPayments = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={25}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
    >
      <Datagrid>
        <TextField source="companyName" />
        <TextField source="paymentId" sortBy="id" />
        <DateMonthTextField source="createdAt" dateType="createdAt"/>
        <TextField source="macAddress" />
        <DateMonthTextField source="validFrom" dateType="validFrom"/>
        <TextField source="term" />
        <DateMonthTextField source="validUntil" dateType="validUntil"/>
      </Datagrid>
    </List>
  );
};
