import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import useHasCreate from "../../../../hooks/useHasCreate.js";
import EditButtons from "../../../../SharedComponents/EditButtons.jsx";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";

const ListBenefitKinds = (props) => {
  const hasCreate = useHasCreate();
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      perPage={25}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="index" />
        <TextField source="name" />
        <TextField source="kind" />
        <EditButtons
          deleteConfirmTitleSource="name"
          hasView={!hasCreate}
          hasEdit={hasCreate}
          hasDelete={hasCreate}
        />
      </Datagrid>
    </List>
  );
};

export default ListBenefitKinds;
