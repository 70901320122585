import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { AccountCircle } from "@material-ui/icons";
import AppBar from "@material-ui/core/AppBar";
import { Redirect, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import Box from "@material-ui/core/Box";
import StartPage from "./StartPage.jsx";
import RoleSelect from "../Registration/RoleSelect.jsx";
import CarrierRegistration from "../Registration/CarrierRegistration.jsx";
import MunicipalityRegistration from "../Registration/MunicipalityRegistration.jsx";
import FinalRegistrationStep from "../Registration/FinalRegistrationStep.jsx";
import CongratulationPage from "../Registration/CongratulationPage.jsx";
import RegistrationProtectedRoute from "../../../Providers/Registration/registrationProtectedRoute.jsx";
import CarrierRegistrationExtended from "../Registration/CarrierRegistrationExtended.jsx";
import CongratulationExtendedPage from "../Registration/CongratulationExtendedPage.jsx";

const StartPageRoot = () => {
  const history = useHistory();
  let { path } = useRouteMatch();

  return (
    <Box>
      <AppBar position="static" color="secondary">
        <Toolbar variant="dense">
          <Box flexGrow={1} />
          <Button color="inherit" onClick={() => history.push("/login")}>
            <Typography>Sign in</Typography>
            {"  "}
            <AccountCircle />
          </Button>
        </Toolbar>
      </AppBar>
      <Switch>
        <Route exact path={path}>
          <StartPage />
        </Route>
        <Route path="/registration/role">
          <RoleSelect />
        </Route>
        <Route path="/registration/carrier">
          <CarrierRegistration />
        </Route>
        <Route path="/registration/extended/:token">
          <CarrierRegistrationExtended/>
        </Route>
        <Route path="/registration/municipality">
          <MunicipalityRegistration />
        </Route>
        <Route path="/registration/extended-success">
          <CongratulationExtendedPage />
        </Route>
        <RegistrationProtectedRoute path="/registration/final">
          <FinalRegistrationStep />
        </RegistrationProtectedRoute>
        <RegistrationProtectedRoute path="/registration/registration-success">
          <CongratulationPage />
        </RegistrationProtectedRoute>
        <Redirect to={path} />
      </Switch>
    </Box>
  );
};

export default StartPageRoot;
