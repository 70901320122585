import React from "react";
import {Create, SimpleForm, TextInput, required, NumberInput, ReferenceInput, SelectInput, useTranslate, regex} from "react-admin";
import MaskedTextField from "../../../../SharedComponents/MaskedTextField.jsx";
import { UUID_MASK } from "../../../../Utils/Constants.js";

const validateRequired = required();
const validateMac = regex(/^[a-zA-Z0-9]{12}$/, "Must contain only letters and numbers. Max 12 chars.");
const validateUUID = regex(
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
  "Not valid UUID",
);

/*eslint-disable react/prop-types*/
const SpecialSelect = (props) => {
  return (
    <SelectInput
      {...props}
      optionText="companyName"
    />
  );
};

const CreateBeacon = (props) => {
  const t = useTranslate();
  return (
    <Create {...props} title="resources.admin-beacons.create">
      <SimpleForm redirect="list">
        <MaskedTextField
          label={t('resources.admin-beacons.fields.uid')}
          mask={UUID_MASK}
          source="uid"
          validate={[validateRequired, validateUUID]}
        />
        <NumberInput source="major" step={1} min={0} validate={validateRequired} />
        <NumberInput source="minor" step={1} min={0} validate={validateRequired} />
        <TextInput inputProps={{ maxLength: 12 }} source="macAddress"  validate={[validateRequired, validateMac]} />
        <ReferenceInput source="carrierId" reference="carriers">
          <SpecialSelect />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateBeacon;
