import React from "react";
import { Create, maxLength, regex, required, SimpleForm, TextInput, usePermissions } from "react-admin";
const validateRequired = required();
const validateLowerCase = regex(/^[a-z-_]+$/, "Повинен містити лише латинські символи та бути в нижньому реєстрі");

const CreateBenefitKind = (props) => {
  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;

  if (!permissions) {
    return null;
  }

  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" validate={validateRequired} />
        <TextInput source="kind" validate={[validateLowerCase, validateRequired]} />
        <TextInput source="langCode" validate={[validateLowerCase, maxLength(2, "Має бути не більше 2 символів")]} />
      </SimpleForm>
    </Create>
  );
};

export default CreateBenefitKind;
