import React from "react";
import { Create, FormDataConsumer, NumberInput, required, SelectInput, SimpleForm, TextInput, usePermissions } from "react-admin";
import { FARE_CHOICES } from "../../../../Utils/Constants.js";
const validateRequired = required();

const CreateFare = (props) => {
  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;

  if (!permissions) {
    return null;
  }

  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" validate={validateRequired} />
        <SelectInput source="kind" choices={FARE_CHOICES} validate={validateRequired} />
        <NumberInput source="price" step={0.1} min={0} validate={validateRequired} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.kind !== 'fixed' && (
              <NumberInput {...rest} source="minAmount" step={0.1} min={0} validate={validateRequired} />
            )
          }
        </FormDataConsumer>
        
      </SimpleForm>
    </Create>
  );
};

export default CreateFare;
