// copy from react-admin

import Polyglot from "./polyglot.js";

const polyglotI18nProvider = (getMessages, initialLocale = "en", polyglotOptions = {}) => {
  let locale = initialLocale;
  const messages = getMessages(initialLocale);
  if (messages instanceof Promise) {
    throw new Error(
      `The i18nProvider returned a Promise for the messages of the default locale (${initialLocale}). Please update your i18nProvider to return the messages of the default locale in a synchronous way.`,
    );
  }
  const polyglot = new Polyglot({
    locale,
    phrases: { "": "", ...messages },
    ...polyglotOptions,
  });
  let translate = polyglot.t.bind(polyglot);

  return {
    translate: (key, options = {}) => translate(key, options),
    changeLocale: (newLocale) =>
      new Promise((resolve) =>
        // so we systematically return a Promise for the messages
        // i18nProvider may return a Promise for language changes,
        resolve(getMessages(newLocale)),
      ).then((messages) => {
        locale = newLocale;
        const newPolyglot = new Polyglot({
          locale: newLocale,
          phrases: { "": "", ...messages },
          ...polyglotOptions,
        });
        translate = newPolyglot.t.bind(newPolyglot);
      }),
    getLocale: () => locale,
  };
};

export default polyglotI18nProvider;
