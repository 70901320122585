import React from "react";
import { Datagrid, List, TextField, EditButton, useTranslate } from "react-admin";
import { Box, Typography } from "@material-ui/core";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";
import { dateToString } from "../../../../Utils/DateUtils.js";

const Buttons = (props) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <EditButton {...props} label="resources.subscription-requests.watch" icon={null} variant="contained" />
    </Box>
  );
};

/*eslint-disable react/prop-types*/
export const DateMonthTextField = (props) => {
  const translate = useTranslate();

  const { record, dateType } = props;
  if (!record[dateType]) return translate("resources.common.noDate");
  return <Typography>{dateToString(new Date(record[dateType]), "dd.MM.yyyy HH:mm")}</Typography>;
};

export const ListSubscriptionRequests = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      hasCreate={false}
      perPage={10}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
    >
      <Datagrid>
        <TextField source="index" />
        <TextField source="companyName" />
        <TextField source="taxNumber" />
        <TextField source="regionName" />
        <TextField source="districtName" />
        <TextField source="cityName" />
        <DateMonthTextField source="createdAt" dateType="createdAt" />
        <Buttons label="" />
      </Datagrid>
    </List>
  );
};
