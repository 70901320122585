import React from "react";
import { Edit, maxLength, regex, required, SimpleForm, TextInput, usePermissions } from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import MyStandardEditToolbarWithList from "../../../../SharedComponents/MyStandardEditToolbarWithList.jsx";

const validateRequired = required();
const validateLowerCase = regex(/^[a-z-_]+$/, "Повинен містити лише латинські символи та бути в нижньому реєстрі");

/*eslint-disable react/prop-types*/
const EditBenefitKind = (props) => {
  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;

  if (!permissions) {
    return null;
  }

  return (
    <Edit {...props} undoable={false} title={<MySimpleBreadcrumbs resource={props.resource} />}>
      <SimpleForm toolbar={<MyStandardEditToolbarWithList nameField="routeName" />} redirect="list">
        <TextInput source="name" validate={validateRequired} />
        <TextInput source="kind" validate={[validateLowerCase, validateRequired]} />
        <TextInput source="langCode" validate={[validateLowerCase, maxLength(2, "Має бути не більше 2 символів")]} />
      </SimpleForm>
    </Edit>
  );
};

export default EditBenefitKind;
