import { usePermissions } from "react-admin";
import { useEffect, useState } from "react";
import { ROLE_CARRIER } from "../Utils/Constants.js";

function useHasCreate() {
  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;
  const [hasCreate, setHasCreate] = useState(false);
  useEffect(() => {
    if (permissions) {
      if (!permissions.user || permissions.user.roles.indexOf(ROLE_CARRIER) === -1) {
        setHasCreate(true);
      }
    }
  }, [permissions]);

  return hasCreate;
}

export default useHasCreate;
